<template>
  <v-dialog
    v-model="modalAddZoneGPS"
    fullscreen
    persistent
    @close="setModalAddZoneGPS(false)"
    transition="scroll-x-transition"
  >
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right">
        <v-btn icon depressed @click="setModalAddZoneGPS(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="gps-container">
        <div class="gps-content">
          <v-row class="map-search">
            <v-col cols="12">
              <gmap-autocomplete
                ref="searchInput"
                class="input text-field"
                placeholder="ຄົ້ນຫາເເຜນທີ່..."
                @place_changed="setPlace"
                :options="{
                  fields: ['geometry', 'formatted_address', 'name'],
                }"
              >
              </gmap-autocomplete>
            </v-col>
          </v-row>
          <div class="gps-section-left">
            <GmapMap
              :center="center"
              :zoom="16"
              style="width: 100%; height: 100%"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center = m.position"
                :draggable="true"
                @dragend="onLocation"
                :icon="markerOptions"
                :animation="2"
                ref="markers"
              />
            </GmapMap>
          </div>
          <div class="gps-section-right">
            <div class="gps-card-right">
              <v-row class="form-gps">
                <v-col cols="12">
                  <label>Select Branch <span>*</span></label>
                  <v-autocomplete
                    filled
                    dense
                    item-text="branch_name"
                    item-value="id"
                    outlined
                    :items="companyBranch"
                    hide-selected
                    v-model="itemData.company_branch_id"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.branch_name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <label>Zone Name <span>*</span></label>
                  <v-text-field
                    class="mt-1"
                    outlined
                    dense
                    v-model="itemData.name"
                    :hint="`${server_errors.name}`"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <label>latitude<span>*</span></label>
                  <v-text-field
                    class="mt-1"
                    outlined
                    dense
                    v-model="center.lat"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <label>longitude <span>*</span></label>
                  <v-text-field
                    class="mt-1"
                    outlined
                    dense
                    v-model="center.lng"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="d-flex is-flex-direction-column">
                  <label>Radius<span>(m)</span></label>
                  <v-text-field
                    class="input-number"
                    type="number"
                    outlined
                    dense
                    v-model="data.circleRadius"
                    :hint="`${server_errors.radius}`"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn outlined class="btn-cancel mt-1" @click="cancel">
                    CanCel
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  style="float: right; display: flex; justify-content: flex-end"
                >
                  <v-btn
                    class="btn-save-change"
                    @click="saveChange"
                    :loading="btnLoading"
                  >
                    Create
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      btnLoading: false,
      modalAddZoneGPS: false,
      data: {
        circleRadius: "",
      },
      center: {
        lat: 17.9806046,
        lng: 102.6056332,
      },
      currentPlace: null,
      markers: [],
      markerOptions: {
        // eslint-disable-next-line global-require
        // url: require('@/assets/logo.png'),
        size: {
          width: 35,
          height: 55,
          f: "px",
          b: "px",
        },
        scaledSize: {
          width: 35,
          height: 55,
          f: "px",
          b: "px",
        },
      },
      itemData: {
        name: "",
        company_branch_id: "",
      },
      companyBranch: [],
      server_errors: {
        name: "",
        radius: "",
      },
    };
  },

  methods: {
    resetFormMap() {
      this.itemData.name = "";
      this.itemData.company_branch_id = "";
      this.data.circleRadius = "";
    },
    fetchBranch() {
      this.$axios.get(`company/list/company/branches`).then((res) => {
        if (res.status === 200) {
          this.companyBranch = res.data.companyBranches;
        }
      });
    },
    cancel() {
      this.setModalAddZoneGPS(false);
    },
    setModalAddZoneGPS(isVisible) {
      this.modalAddZoneGPS = isVisible;
      this.$emit("change", isVisible);
    },
    createNewAddressName() {
      const CUSTOMIZE = "#CUSTOM ADDRESS:";
      return this.isCreate
        ? this.currentAddress
        : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
    },
    setPlace(place) {
      this.currentPlace = place;
      this.placeMarker();
    },
    onLocation(evt) {
      this.center.lat = evt.latLng.lat();
      this.center.lng = evt.latLng.lng();
      this.address = this.createNewAddressName();
      this.data.latitude = this.center.lat;
      this.data.longitude = this.center.lng;

      console.log(this.data.latitude, "latitude");
      console.log(this.data.longitude, "longitude");
    },
    placeMarker() {
      this.markers = [];
      this.places = [];
      if (this.currentPlace && this.currentPlace.geometry != null) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.animateMarker();
      } else {
        const marker = {
          lat: this.center.lat,
          lng: this.center.lng,
        };
        this.markers.push({ position: marker });
        this.animateMarker();
      }
      // set address
      if (this.$refs.searchInput) {
        this.address = this.$refs.searchInput.$el.value;
      } else {
        // this.address = this.currentPlace.formatted_address;
      }
      this.onDataChange();
    },
    onDataChange() {
      this.$emit("onDataChange", {
        address: this.address,
        position: this.center,
      });
    },
    onSave() {
      this.$emit("onSave", {
        address: this.address || this.currentAddress || "Unnamed Location",
        position: this.center,
        isCreate: this.isCreate,
      });
    },
    animateMarker() {
      this.$nextTick(() => {});
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.placeMarker();
      });
    },
    saveChange() {
      this.btnLoading = true;
      const item = {
        company_branch_id: this.itemData.company_branch_id,
        name: this.itemData.name,
        zone_type: this.$route.params.zone_type,
        longitude: this.center.lng,
        latitude: this.center.lat,
        radius: this.data.circleRadius,
      };

      this.$axios
        .post(`company/clock-in-zone`, item)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.resetFormMap();
          }
          this.btnLoading = false;
          this.setModalAddZoneGPS(false);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.btnLoading = false;
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
  },

  mounted() {
    this.getLocation();
  },
  watch: {
    visible(val) {
      this.modalAddZoneGPS = val;
    },
  },
  created() {
    this.getLocation();
    this.placeMarker();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.gps-container {
  width: 100%;
  height: auto;
  // background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;

  .gps-content {
    width: 100%;
    height: 90vh;
    // background-color: yellow;
    background-color: #eeeeee;
    display: flex;
    position: relative;

    .map-search {
      position: absolute;
      top: 25px;
      left: 25%;
      z-index: 1;
      width: 500px;
      font-family: $font-family !important;
    }

    .gps-section-left {
      flex: 5;
      height: 100%;
      background-color: #eeeeee;
      padding-right: 15px;
    }

    .gps-section-right {
      flex: 1;
      height: 100%;
      //background-color: #6CB669;
      position: relative;

      .gps-card-right {
        width: 260px;
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        right: 0;
        padding: 40px 20px;

        .form-gps {
          font-family: $font-family;
          text-transform: capitalize;
          letter-spacing: 1px;
          font-weight: 500;

          span {
            color: #d70f64;
            font-weight: normal;
            text-transform: lowercase;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
