<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="400px">
      <v-card>
        <v-card-title>
          <h1 class="ml-5">
            Delete Wifi<span
              ><i class="far fa-exclamation-circle" style="color: #e96464"></i
            ></span>
          </h1>
          <template>
            <div class="container-delete">
              <div class="content-card-layout">
                <div class="main-content-card">
                  <h4 class="mb-10" style="font-weight: 400;color:red;">
                    Do you want to delete ?
                  </h4>
                </div>
              </div>
            </div>
          </template>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="DeleteItem(wifi_id)">
            Delete
          </v-btn>
          <v-btn color="dark" text @click="closeModal">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { emit } from "process";

export default {
  props: ["dialogD", "wifi_id"],
  data() {
    return {
      wifi_ip: "",
      description: "",
      server_errors: {
        description: "",
        wifi_ip: "",
      },
      btnLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    DeleteItem(wifi_id) {
      this.$axios.delete(`company/wifi-ip/${wifi_id}`).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit("close");
            this.$emit("success");
            this.$notification.OpenNotification_DeleteItem_OnSuccess(
              "top-right",
              "danger",
              3000
            );
          }, 300);
        }
      });
    },
  },
  computed: {
    showModal: function() {
      return this.dialogD;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}
.btn {
  padding: 30px !important;
}
.btn-save {
  color: white !important;
  margin-right: 30px;
}
.user {
  color: blue;
}
</style>
